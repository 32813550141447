import { useTooTipContext } from "../ContextTip";

function ToolTip(props)
{   const {tipText,tip} = useTooTipContext();
    return (
    <div className="ToolTip" >
    <div className="Tip" style={{visibility:props.toggle['visibility'],borderLeft:tip['ht']+' solid transparent',borderRight:tip['ht']+' solid transparent',borderBottom:tip['wd']+' solid red'}}></div>
    <div className="Tip-Text" style={{visibility:props.toggle['visibility'],...tipText['texstyle']}}>{props.tptxt}</div>
    </div>
)

}

export default ToolTip ;