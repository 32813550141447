import Button from './Button'

function Mobile(){

  return (
    <div className="mobile-container">
      <div className="mobile-screen">
        <Button/>
      </div>
    </div>
 )
}

export default Mobile ;
