import './App.css';
import Mobile from "./Components/Mobile";
import UpdateForm from './Components/UpdateForm';
import ToolContextProvider from './ContextTip';
function App() {
  return (
    <ToolContextProvider>
    <div className="page-container">
      <UpdateForm />
      <Mobile />
    </div>
    </ToolContextProvider>
  );
}

export default App;
