import { useTooTipContext } from "../ContextTip";
import { useState } from "react";

function NumberInput(props){
   const [val,seVal] = useState(10);
   const {tip,setTip,tipText,setTipText} = useTooTipContext();
   const handler = (e)=>{

         seVal(e.target.value);
         const sz =  (val/16)+'rem';

      if(props.label === 'Text Size')
         {setTipText({...tipText,'texstyle':{...tipText['texstyle'],'fontSize':sz}});}
      else if(props.label === 'Padding')
         {setTipText({...tipText,'texstyle':{...tipText['texstyle'],'padding':sz}});}
      else if(props.label === 'Corner radius')
         {setTipText({...tipText,'texstyle':{...tipText['texstyle'],'borderRadius':sz}});}
      else if(props.label === 'Tooltip width')
         {setTipText({...tipText,'texstyle':{...tipText['texstyle'],'width':sz}});}
     else if(props.label === 'Arrow width')
         {setTip({...tip,'wd':sz});}
     else 
         {setTip({...tip,'ht':sz});}
   }
    return (
        <div className="Component">
          <div className="Label">{props.label}</div>
          <input type="number" value={val}  onChange={handler}/>
        </div>)
}

export default NumberInput ;