import { useState } from "react";
import { useTooTipContext } from "../ContextTip";

function ColorPicker(props){
     const [color_,setColor_] = useState('#000000');
     const {tipText,setTipText} = useTooTipContext();
     
     const handler = (e) =>{
          setColor_(e.target.value);
          if(props.label === 'Text Colour')
              {setTipText({...tipText,'texstyle':{...tipText['texstyle'],'color':color_}}) ;} 
          else
          {setTipText({...tipText,'texstyle':{...tipText['texstyle'],'background':color_}}) ;}
     }

    return (
        <div className="Component">
          <div className="Label">{props.label}</div>
          <div className="Picker">
            <input type="text" placeholder="#" style={{flexGrow:3}} value={color_} onChange={(e)=> {setColor_(e.target.value)}} />
            <input type="color" value={color_} onChange={handler} />
          </div>      
        </div>)
}

export default ColorPicker ;
