import { useState } from "react";
import ToolTip from "./ToolTip";
import { useTooTipContext } from "../ContextTip";

function Button(){
    const [visible,setVisible] = useState('hidden');
    const {tipText,btntype} = useTooTipContext();


    const hoverHandler = ()=>{
      
       if(visible === 'hidden')
           {setVisible('visible');}
        else 
           {setVisible('hidden');}
    }

    const ToolTipStyle = {visibility: visible} ;
    return (
        <div className="ToolTip">
    <div className="Button" onClick={hoverHandler}>{btntype}</div>
       <ToolTip  toggle={ToolTipStyle} tptxt={tipText['tiptxt']}/>
       </div>
    )
}

export default Button ;