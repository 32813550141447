import { useTooTipContext } from "../ContextTip";

function Cascader(){
   const {setbtn,btntype} = useTooTipContext();
    return (
        <div className="Component">
          <div className="Label">Target Element</div>
          <select  value={btntype} onChange={(e)=>{setbtn(e.target.value)}}>
            <option value="">--Select--</option>
            <option value="Button1">Button1</option>
            <option value="Button2">Button2</option>
            <option value="Button3">Button3</option>
          </select>
        </div>)
}
export default Cascader ;