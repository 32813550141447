import { createContext , useContext  , useState } from "react";

const ToolTipContext = createContext();
const ToolTipStyle = {wd:'20px',ht:'10px'} // initial values .
const ToolTextStyle = {tiptxt:'This text is displayed',texstyle:{color:'#ffffff',background:'#000000',fontSize:'16px',padding:'10px',borderRadius:'0px',width:'10px'}} // initial values .
const ibuttontype = 'Button1'

function ToolContextProvider({children}){
  const [tip,setTip] = useState(ToolTipStyle);
  const [tipText,setTipText] = useState(ToolTextStyle) ;
  const [btntype,setbtn] = useState(ibuttontype);

  return (
             <ToolTipContext.Provider value={{'tip':tip,'tipText':tipText , 'setTip':setTip , 'setTipText':setTipText ,'btntype':btntype,'setbtn':setbtn}}>
                 {children}
             </ToolTipContext.Provider>
  )

}

export const useTooTipContext = () => useContext(ToolTipContext);
export default ToolContextProvider ;
