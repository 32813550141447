import Cascader from "./Cascader";
import NumberInput from "./NumberInput";
import TextInput from "./TextInput";
import ColorPicker from "./ColorPicker" ;


function UpdateForm(){

    return (
    <div className="Form-Container">
     <div className="Form-Component-Set">
       <Cascader />
     </div>
     <div className="Form-Component-Set">
       <TextInput />
     </div>
     <div className="Form-Component-Set">
       <NumberInput label="Text Size" />
       <NumberInput label="Padding" />
     </div>
     <div className="Form-Component-Set">
       <ColorPicker label="Text Colour"  />
     </div>
     <div className="Form-Component-Set">
       <ColorPicker label="Background Colour" />
     </div>
     <div className="Form-Component-Set">
       <NumberInput label="Corner radius"/>
       <NumberInput label="Tooltip width" />
     </div>
     <div className="Form-Component-Set">
       <NumberInput label="Arrow width" />
       <NumberInput label="Arrow height" />
     </div>
    </div>)
}

export default UpdateForm ;