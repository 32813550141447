import { useTooTipContext } from "../ContextTip";

function TextInput(){
  const {tipText,setTipText} = useTooTipContext() ;
  return (
    <div className="Component">
     <div className="Label">Tooltip text</div>
     <input type="text" placeholder="Input"  value={tipText['tiptxt']} onChange={(e)=>{setTipText({...tipText,'tiptxt':e.target.value})}}/>
    </div>)
}

export default TextInput ;